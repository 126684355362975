import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import CasesService from "../../../services/cases.service";
import { Col, Radio, Row, Space, Tabs} from "antd";
import ReactPlayer from "react-player";
import '../cases.css';

export function CaseDetails() {
    const params = useParams();
    const [caseDetails, setCaseDetails] = React.useState({});
    const [tabs, setTabs] = React.useState([]);
    const [openTab, setOpenTab] = React.useState('description')

    const changeTab = function (key) {
        setOpenTab(key);
    }

    useEffect(() => {
        CasesService.caseById(params.id).then(response => {
            if (response.success) {
                setCaseDetails(response.data);
                setTabs([{
                    label: `Description`,
                    key: 'description',
                    children: <CaseDescription caseDetails={response.data} changeTab={changeTab}/>
                }, {
                    label: "Q&A",
                    key: 'questions',
                    children: <CaseTest caseDetails={response.data} changeTab={changeTab}/>,
                }, {
                    label: 'Explanation',
                    key: 'explanation',
                    children: <CaseExplanation caseDetails={response.data} changeTab={changeTab}/>,
                }]);
            }
        }).catch(error => {
            console.log(error);
        })
    }, []);


    return (<div className={"container"}>
        {caseDetails && <Row>
            <Col xl={18} lg={16} md={24}>
                <h2 className={"case-title"}>{caseDetails.case_name}</h2>
                <p className={"case-category"}>Medicine > CVS</p>
                <div className={"mt-4"}>
                    <ReactPlayer url={caseDetails.case_files} controls={true} width={"100%"} height={"auto"}/>
                </div>
                <Tabs rootClassName={"mt-3 description-tabs"} type={"card"} activeKey={openTab} items={tabs}
                      onTabClick={(key) => changeTab(key)}>
                </Tabs>
            </Col>
            <Col xl={6} lg={8} md={24}>

            </Col>
        </Row>}
    </div>);
}

export function CaseDescription({caseDetails, changeTab}) {

    return (<div>
        {caseDetails.case_description.map(cd => (<div key={cd.id}>
            <h2 className={"app-section-title mt-0"}>{cd.title}</h2>
            <p>{cd.description}</p>
        </div>))}
        <Row align={'middle'} className={"px-2"}>
            {caseDetails.case_description.map(cd => (
                <Col lg={6} md={8} key={cd.id}>
                    <img src={cd.description_file} className={'case-description-image'}/>
                </Col>
            ))}
        </Row>
        <Row className={"mt-2"} align={'middle'} justify={'end'}>
            <Col xl={6} xs={10}>
                <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                        onClick={() => changeTab('questions')}>
                    Next
                </button>
            </Col>
        </Row>
    </div>)
}

export function CaseExplanation({caseDetails, changeTab}) {
    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: caseDetails.case_analysis}}>
            </div>
            <Row className={"mt-2"} align={'middle'} justify={'start'}>
                <Col xl={6} xs={10}>
                    <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                            onClick={() => changeTab('questions')}>
                        Previous
                    </button>
                </Col>
            </Row>
        </div>)
}

export function CaseTest({caseDetails, changeTab}) {
    const [quiz, setQuiz] = React.useState([]);
    const [currentQuestion, setCurrentQuestion] = React.useState(1)
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    useEffect(() => {
        CasesService.caseQuestions(caseDetails.id).then(response => {
            if (response.success) {
                setQuiz(response.data.quiz);
            }
        }).catch(error => {
            console.log(error);
        })
    }, []);

    const onSelectionChange = (checkedValues) => {
        const updatedOptions = [...selectedOptions];
        updatedOptions[currentQuestion - 1] = checkedValues.target.value;
        setSelectedOptions(updatedOptions);
        console.log(selectedOptions);
        console.log('checked = ', checkedValues.target.value);
    };

    let currentQuestionData = quiz[currentQuestion - 1];

    return (<div>
        {
            quiz.length > 0 && <div className={'case-question'}>
                <h2 className={"app-section-title mt-0"}>{currentQuestionData.question}</h2>
                <div className={"question-options mb-3"}>
                    <Radio.Group onChange={onSelectionChange}>
                        <Space direction="vertical">
                            <Radio disabled={selectedOptions[currentQuestion - 1]} value={currentQuestionData.option_a}
                                   className={selectedOptions[currentQuestion - 1] && "a" === currentQuestionData.correct_ans.toLowerCase() ? 'is-correct' : 'is-wrong'}>{currentQuestionData.option_a}</Radio>
                            {
                                "a" === currentQuestionData.correct_ans.toLowerCase() && selectedOptions[currentQuestion - 1] &&
                                <div>
                                    <p>{currentQuestionData.explanation}</p>
                                    {
                                        currentQuestionData.explanation_file &&
                                        <Row align={'middle'} className={"px-2"}>
                                            <Col lg={6} md={8}>
                                                <img src={currentQuestionData.explanation_file}
                                                     className={'case-description-image'}/>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                            <Radio disabled={selectedOptions[currentQuestion - 1]}
                                   value={currentQuestionData.option_b}
                                   className={selectedOptions[currentQuestion - 1] && "b" === currentQuestionData.correct_ans.toLowerCase() ? 'is-correct' : 'is-wrong'}>{currentQuestionData.option_b}</Radio>
                            {
                                "b" === currentQuestionData.correct_ans.toLowerCase() && selectedOptions[currentQuestion - 1] &&
                                <div>
                                    <p>{currentQuestionData.explanation}</p>
                                    {
                                        currentQuestionData.explanation_file &&
                                        <Row align={'middle'} className={"px-2"}>
                                            <Col lg={6} md={8}>
                                                <img src={currentQuestionData.explanation_file}
                                                     className={'case-description-image'}/>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                            <Radio disabled={selectedOptions[currentQuestion - 1]}
                                   value={currentQuestionData.option_c}
                                   className={selectedOptions[currentQuestion - 1] && "c" === currentQuestionData.correct_ans.toLowerCase() ? 'is-correct' : 'is-wrong'}>{currentQuestionData.option_c}</Radio>
                            {
                                "c" === currentQuestionData.correct_ans.toLowerCase() && selectedOptions[currentQuestion - 1] &&
                                <div>
                                    <p>{currentQuestionData.explanation}</p>
                                    {
                                        currentQuestionData.explanation_file &&
                                        <Row align={'middle'} className={"px-2"}>
                                            <Col lg={6} md={8}>
                                                <img src={currentQuestionData.explanation_file}
                                                     className={'case-description-image'}/>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                            <Radio disabled={selectedOptions[currentQuestion - 1]}
                                   value={currentQuestionData.option_d}
                                   className={selectedOptions[currentQuestion - 1] && "d" === currentQuestionData.correct_ans.toLowerCase() ? 'is-correct' : 'is-wrong'}>{currentQuestionData.option_d}</Radio>
                            {
                                "d" === currentQuestionData.correct_ans.toLowerCase() && selectedOptions[currentQuestion - 1] &&
                                <div>
                                    <p>{currentQuestionData.explanation}</p>
                                    {
                                        currentQuestionData.explanation_file &&
                                        <Row align={'middle'} className={"px-2"}>
                                            <Col lg={6} md={8}>
                                                <img src={currentQuestionData.explanation_file}
                                                     className={'case-description-image'}/>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                        </Space>
                    </Radio.Group>
                </div>

                <Row className={"mt-2"} align={'middle'} justify={'space-between'}>
                    <Col xl={6} xs={10}>
                        {
                            currentQuestion === 1 &&
                            <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                                    onClick={() => changeTab('description')}>
                                Previous
                            </button>
                        }
                        {
                            currentQuestion > 1 &&
                            <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                                    onClick={() => setCurrentQuestion(currentQuestion - 1)}>
                                Previous
                            </button>
                        }
                    </Col>
                    <Col xl={6} xs={10}>
                        {
                            currentQuestion < quiz.length &&
                            <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                                    onClick={() => setCurrentQuestion(currentQuestion + 1)}>
                                Next
                            </button>
                        }
                        {
                            currentQuestion >= quiz.length &&
                            <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                                    onClick={() => changeTab('explanation')}>
                                Next
                            </button>
                        }
                    </Col>
                </Row>
            </div>
        }
    </div>)
}