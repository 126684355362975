import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Layout,
    message,
    notification,
    Row,
    Select
} from "antd";
import "./auth.css";
import React, {useEffect} from "react";
import AuthService from "../../services/auth.service";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../utils/UserContext";
import {AuthLeftSection} from "../../components/AuthLeftSection";
import {Option} from "antd/es/mentions";
import {all} from "axios";

export function Register() {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const [states, setStates] = React.useState([]);
    const [cities, setCities] = React.useState([]);
    const [colleges, setColleges] = React.useState([]);
    const [errors, setErrors] = React.useState([]);
    const [allValues, setAllValues] = React.useState({});
    const [emailVerification, setEmailVerification] = React.useState(false);
    const [mobileVerification, setMobileVerification] = React.useState(false);
    const [emailToVerify, setEmailToVerify] = React.useState(null);
    const [mobileToVerify, setMobileToVerify] = React.useState(7016910584);
    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const [emailForm] = Form.useForm();
    const [phoneForm] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);

        setAllValues({
            ...allValues,
            ...values
        });

        let dob = new Date(values.date_of_birth);
        let body = {
            first_name: allValues.first_name,
            last_name: allValues.last_name,
            email: allValues.email,
            phone: allValues.phone_number,
            state_id: allValues.state_id,
            city_id: allValues.city_id,
            college_id: allValues.college_id,
            current_year: allValues.current_year,
            preparing_for: values.preparing_for,
            birth_date: dob.getFullYear().toFixed() + '-'+ dob.getMonth() + '-' + dob.getDate(),
            password: values.password
        };
        AuthService.register(body).then(response => {
            setLoading(false);
            if(response.success){
                setErrors([]);
                setEmailVerification(true);
                setEmailToVerify(allValues.email);
                setMobileToVerify(allValues.phone_number);
                sendEmailOtp();
            }
            else{
                setStep(1);
                setErrors(response.message);
            }
        })
    }

    const sendEmailOtp = (showNotification = false) => {
        AuthService.sendEmailOtp(allValues.email).then(response => {
            if(response.success){
                if(showNotification){
                    api.info({ message: response.data});
                }
            }
        })
    }

    const sendMobileOtp = (showNotification = false, resend = false) => {
        AuthService.sendMobileOtp(allValues.phone_number, resend).then(response => {
            if(response.message){
                setErrors({});
                if(showNotification){
                    api.info({ message: response.message});
                }
            }
            else{
                setErrors({phone:[response.message]});
            }
        })
    }

    const onEmailVerify = (values) => {
        setLoading(true)
        AuthService.verifyEmail({email: allValues.email, otp: values.otp}).then(response => {
            if(response.success){
                setErrors({});
                api.info({ message: response.data});
                sendMobileOtp(false, false);
                setMobileVerification(true);
                setEmailVerification(false);
            }
            else{
                setErrors({email:[response.message]});
            }
            setLoading(false)
        })
    }

    const onPhoneVerify = (values) => {
        AuthService.verifyMobile({phone: allValues.phone_number, otp: values.otp}).then(response => {
            if(response.success){
                setErrors([]);
                api.info({ message: response.message});
                localStorage.setItem('user', JSON.stringify(response.data));
                navigate('/dashboard');
            }
            else{
                setErrors({phone:[response.message || response.error]});
            }
        })
    }

    const getStateWithCities = () => {
        AuthService.statesList().then(data => {
            setStates(data.data);
        })

        AuthService.collegeList().then(data => {
            setColleges(data.data);
        })
    }

    useEffect(() => {
        getStateWithCities();
    }, []);

    const selectBefore = (
        <Select defaultValue="91" style={{ width: 90 }}>
            <Select.Option value="91">+91</Select.Option>
            <Select.Option value="1">+1</Select.Option>
            <Select.Option value="92">+92</Select.Option>
        </Select>
    );

    const nextStep = () => {
        form.validateFields().then((values) => {
            setAllValues((prevValues) => ({
                ...prevValues,
                ...values,
            }));
            setStep(step + 1);
        }).catch((info) => {
            console.log('Validation failed:', info);
        });
    }

    return (
        <div className="auth-container">
            {contextHolder}
            <div className={'container'}>
                <Row justify="center" align="middle">
                    <Col lg={12} md={24} className={"d-flex align-items-center"}>
                        <AuthLeftSection />
                    </Col>
                    <Col lg={12} md={24}>
                        <div className={"auth-form-container"}>
                            {!emailVerification && !mobileVerification &&
                                <div className={"w-100"}>
                                    <h2 className={"main-title text-center"}>SignUp</h2>
                                    <p className={"text-center"}>Let's get you setup for success</p>
                                    <Form
                                        form={form}
                                        name={'register'}
                                        layout="vertical"
                                        className={"auth-form"}
                                        requiredMark={false}
                                        onFinish={onFinish}
                                    >
                                        {step === 1 &&
                                            <div>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    className={"mb-2"}
                                                    name="first_name"
                                                    rules={[{required: true, message: 'Please enter your first name!'}]}
                                                >
                                                    <Input type="text" variant={"filled"} placeholder="First Name"
                                                           rootClassName="form-control"/>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    name="last_name"
                                                    className={"mb-2"}
                                                    rules={[{required: true, message: 'Please enter your last name!'}]}
                                                >
                                                    <Input type="text" variant={"filled"} placeholder="Last Name"
                                                           rootClassName="form-control"/>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    name="email"
                                                    className={"mb-2"}
                                                    rules={[{required: true, message: 'Please enter your email!'}]}
                                                >
                                                    <Input type="email" variant={"filled"} placeholder="Email Address"
                                                           rootClassName="form-control"/>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    name="phone_number"
                                                    className={"mb-2"}
                                                    rules={[{required: true, message: 'Please enter Phone number!'}]}
                                                >
                                                    <InputNumber variant={"filled"} addonBefore={selectBefore}
                                                                 placeholder="Phone Number"
                                                                 rootClassName="form-control no-arrows"/>
                                                </Form.Item>
                                                {
                                                    Object.keys(errors).map(key => {
                                                        return (
                                                            <p className={"text-danger"}>{errors[key][0]}</p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        {step === 2 &&
                                            <div>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    className={"mb-2"}
                                                    name="state_id"
                                                    rules={[{required: true, message: 'Please select state!'}]}
                                                >
                                                    <Select
                                                        onChange={(value) => setCities(states.find(s => s.id === value).cities)}
                                                        variant={"filled"} placeholder="Select State"
                                                        rootClassName="form-control">
                                                        {states.map(state => (
                                                            <Select.Option
                                                                value={state.id}>{state.state_name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    className={"mb-2"}
                                                    name="city_id"
                                                    rules={[{required: true, message: 'Please select city!'}]}
                                                >
                                                    <Select variant={"filled"} placeholder="Select City"
                                                            rootClassName="form-control">
                                                        {cities.map(city => (
                                                            <Select.Option
                                                                value={city.id}>{city.city_name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    className={"mb-2"}
                                                    name="college_id"
                                                    rules={[{required: true, message: 'Please select college!'}]}
                                                >
                                                    <Select variant={"filled"} placeholder="Select College"
                                                            rootClassName="form-control">
                                                        {colleges.map(college => (
                                                            <Select.Option
                                                                value={college.id}>{college.college_name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    className={"mb-2"}
                                                    name="current_year"
                                                    rules={[{required: true, message: 'Please select current year!'}]}
                                                >
                                                    <Select variant={"filled"} placeholder="Select Year"
                                                            rootClassName="form-control">
                                                        <Select.Option value="1st Year">1st</Select.Option>
                                                        <Select.Option value="2nd Year">2nd</Select.Option>
                                                        <Select.Option value="3rd Year">3rd</Select.Option>
                                                        <Select.Option value="4th Year">4th</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        }
                                        {step === 3 &&
                                            <div>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    className={"mb-2"}
                                                    name="preparing_for"
                                                    rules={[]}
                                                >
                                                    <Input type="text" variant={"filled"} placeholder="Preparing for"
                                                           rootClassName="form-control"/>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    name="date_of_birth"
                                                    className={"mb-2"}
                                                    rules={[{required: true, message: 'Please select Date of Birth!'}]}
                                                >
                                                    <DatePicker format={'YYYY-MM-DD'} placeholder={"Select Date of Birth"} rootClassName={'form-control'}
                                                                variant={"filled"}/>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    name="password"
                                                    className={"mb-2"}
                                                    rules={[{required: true, message: 'Please enter password!'}]}
                                                >
                                                    <Input.Password variant={"filled"} placeholder="Password"
                                                                    rootClassName="form-control"/>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label=""
                                                    name="confirm_password"
                                                    className={"mb-2"}
                                                    rules={[{
                                                        required: true,
                                                        message: 'Please confirm password!'
                                                    }, ({getFieldValue}) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('The two passwords do not match!'));
                                                        },
                                                    })]}
                                                >
                                                    <Input.Password variant={"filled"} placeholder="Confirm Password"
                                                                    rootClassName="form-control"/>
                                                </Form.Item>
                                            </div>
                                        }

                                        {step === 3 &&
                                            <Button disabled={loading} type="primary" className={'btn btn-block mt-4'}
                                                    htmlType="submit">
                                                {loading ? "Please wait ..." : 'Sign Up'}
                                            </Button>}
                                        {step < 3 && <Button type="primary" onClick={() => nextStep()}
                                                             className={'btn btn-block mt-4'} htmlType="button">
                                            Next
                                        </Button>}
                                        <div className={'text-center mt-3'}>
                                            <p>Already have an account?, <a href={"/login"}>Login</a></p>
                                        </div>
                                    </Form>
                                </div>
                            }

                            {emailVerification && <div className={"w-100"}>
                                <h2 className={"main-title text-center"}>Verify Email</h2>
                                <p className={"text-center"}>Enter OTP sent on your email</p>
                                <Form
                                    form={emailForm}
                                    name={'email_verification'}
                                    layout="vertical"
                                    className={"auth-form"}
                                    requiredMark={false}
                                    onFinish={onEmailVerify}
                                >
                                    <Form.Item
                                        hasFeedback
                                        label=""
                                        name="otp"
                                        className={"mb-2 text-center"}
                                        rules={[{
                                            required: true,
                                            message: 'Please enter otp!'
                                        }]}>
                                        <Input.OTP length={6} variant={'filled'} className={'form-control'}/>
                                    </Form.Item>
                                    <Row justify={'end'} align={"middle"}>
                                        <Col lg={12} md={24} className={"text-end"}>
                                            <p className={'cursor-pointer'} onClick={() => sendEmailOtp(true)}>Resend Otp</p>
                                        </Col>
                                    </Row>
                                    <div>
                                        {
                                            Object.keys(errors).map(key => {
                                                return (
                                                    <p className={"text-danger"}>{errors[key][0]}</p>
                                                )
                                            })
                                        }
                                    </div>

                                    <Button disabled={loading} type="primary" className={'btn btn-block mt-4'}
                                            htmlType="submit">
                                        {loading ? "Please wait ..." : 'Verify'}
                                    </Button>

                                    <div className={'text-center mt-3'}>
                                        <p>Already have an account?, <a href={"/login"}>Login</a></p>
                                    </div>
                                </Form>
                            </div>}
                            {mobileVerification && <div className={"w-100"}>
                                <h2 className={"main-title text-center"}>Verify Mobile</h2>
                                <p className={"text-center"}>Enter OTP sent on your phone number</p>
                                <Form
                                    form={phoneForm}
                                    name={'phone_verification'}
                                    layout="vertical"
                                    className={"auth-form"}
                                    requiredMark={false}
                                    onFinish={onPhoneVerify}
                                >
                                    <Form.Item
                                        hasFeedback
                                        label=""
                                        name="otp"
                                        className={"mb-2 text-center"}
                                        rules={[{
                                            required: true,
                                            message: 'Please enter otp!'
                                        }]}>
                                        <Input.OTP length={6} variant={'filled'} className={'form-control'}/>
                                    </Form.Item>
                                    <Row justify={'end'} align={"middle"}>
                                        <Col lg={12} md={24} className={"text-end"}>
                                            <p className={'cursor-pointer'} onClick={() => sendMobileOtp(true, true)}>Resend Otp</p>
                                        </Col>
                                    </Row>
                                    <div>
                                        {
                                            Object.keys(errors).map(key => {
                                                return (
                                                    <p className={"text-danger"}>{errors[key][0]}</p>
                                                )
                                            })
                                        }
                                    </div>

                                    <Button disabled={loading} type="primary" className={'btn btn-block mt-4'}
                                            htmlType="submit">
                                        {loading ? "Please wait ..." : 'Verify'}
                                    </Button>

                                    <div className={'text-center mt-3'}>
                                        <p>Already have an account?, <a href={"/login"}>Login</a></p>
                                    </div>
                                </Form>
                            </div>}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}