import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Login} from "../pages/auth/Login";
import {AdminLayout} from "../components/layouts/AdminLayout";
// import {Register} from "../pages/auth/Register";
// import {ForgotPassword} from "../pages/auth/ForgotPassword";
// import {Dashboard} from "../pages/Dashboard/Dashboard";
import UserMiddleware from "../middlewares/UserMiddleware";
import {Register} from "../pages/auth/Register";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/register" element={<Register/>} />
            {/*<Route path="/forgot-password" element={<ForgotPassword/>} />*/}
            {/*<Route path="/dashboard" element={<UserMiddleware component={Dashboard} isProtectedRoute={true} />} />*/}

            <Route path="*" element={<UserMiddleware component={AdminLayout} isProtectedRoute={true} />} />
        </Routes>
    );
}

export default AppRoutes;