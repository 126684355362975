import {Button, Checkbox, Col, Form, Input, Layout, Row} from "antd";
import "./auth.css";
import React from "react";
import AuthService from "../../services/auth.service";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../utils/UserContext";
import {AuthLeftSection} from "../../components/AuthLeftSection";

export function Login() {
    const navigate = useNavigate();
    const { setUser } = React.useContext(UserContext);
    const [loading, setLoading] = React.useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);
        AuthService.login(values).then(response => {
            setLoading(false);
            form.resetFields();
            if(response.success) {
                localStorage.setItem('user', JSON.stringify(response.data));
                setUser(response.data);
                navigate('/dashboard');
            }
            else{
                console.log(response);
                let errors = [];
                if(response.message.email) {
                    errors.push({
                        name: 'email',
                        errors: response.message.email,
                        value: values.email
                    })
                }
                if(response.message.password){
                    errors.push({
                        name: 'password',
                        errors: response.message.password,
                    })
                }
                if(errors.length === 0){
                    errors.push({
                        name: 'email',
                        errors: [response.message],
                        value: values.email
                    })
                }
                form.setFields(errors);
            }
        }).catch(err => {
            setLoading(false)
            console.log(err);
        })
    }
    return (
        <div className="auth-container">
            <div className={'container'}>
                <Row justify="center" align="middle">
                    <Col lg={12} md={24} className={"d-flex align-items-center"}>
                        <AuthLeftSection />
                    </Col>
                    <Col lg={12} md={24}>
                        <div className={"auth-form-container"}>
                            <div className={"w-100"}>
                                <h2 className={"main-title text-center"}>Login</h2>
                                <p>Sign in to your account</p>
                                <Form
                                    form={form}
                                    name={'login'}
                                    layout="vertical"
                                    className={"auth-form"}
                                    requiredMark={false}
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        hasFeedback
                                        label=""
                                        name="email"
                                        rules={[{required: true, message: 'Please enter your email or phone number!'}]}
                                    >
                                        <Input type="text" variant={"filled"} placeholder="Enter Email Address or Phone Number" rootClassName="form-control"/>
                                    </Form.Item>
                                    <Form.Item
                                        hasFeedback
                                        label=""
                                        name="password"
                                        className={"mb-2"}
                                        rules={[{required: true, message: 'Please enter your password!'}]}
                                    >
                                        <Input.Password variant={"filled"} placeholder="Password" rootClassName="form-control"/>
                                    </Form.Item>
                                    <Row justify={'space-between'} align={"middle"}>
                                        <Col lg={12} md={24}>
                                            <Form.Item
                                                className={"mb-0"}
                                                name="remember"
                                                valuePropName="checked">
                                                <Checkbox>Remember me</Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} md={24} className={"text-end"}>
                                            <a href={"/forgot-password"}>Forgot Password?</a>
                                        </Col>
                                    </Row>
                                    {/*{*/}
                                    {/*    Object.keys(errors).map(key => {*/}
                                    {/*        return (*/}
                                    {/*            <p>{errors[key][0]}</p>*/}
                                    {/*        )*/}
                                    {/*    })*/}
                                    {/*}*/}
                                    <Button disabled={loading} type="primary" className={'btn btn-block mt-4'} htmlType="submit">
                                        {loading ? "Please wait ..." : 'Login'}
                                    </Button>
                                    <div className={'text-center mt-3'}>
                                        <p>Don't have account? <a href={"/register"}>Sign Up</a></p>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}