import { Layout } from 'antd';
import Sidebar from "./Sidebar"
import PageContent from "./PageContent"

const {Sider, Content: AntdContent } = Layout;

export const AdminLayout = () => {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible width={'20%'} theme={'light'} breakpoint={'md'}>
                <Sidebar />
            </Sider>
            <Layout className="main-content">
                <AntdContent>
                    <div className="site-layout-background">
                        <PageContent />
                    </div>
                </AntdContent>
            </Layout>
        </Layout>
    );
};

export default AdminLayout;