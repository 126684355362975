let UsersService = {

    currentUser: () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.user : null;
    },

    userToken: () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.access_token : null;
    }
}

export default UsersService;