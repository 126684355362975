import React from 'react';
import { Carousel } from 'antd';
import "./AuthSlider.css";

export function AuthSlider(){
    return (
        <div className={"auth-slider"}>
            <Carousel autoplay>
                <div>
                    <h4>Welcome To Stetthups</h4>
                    <p>Your one stop solution for successfully clearing medical exams like lorium ipsum</p>
                </div>
                <div>
                    <h4>Animated Videos</h4>
                    <p>Your one stop solution for successfully clearing medical exams like lorium ipsum</p>
                </div>
                <div>
                    <h4>Monthly Subscriptions</h4>
                    <p>Your one stop solution for successfully clearing medical exams like lorium ipsum</p>
                </div>
            </Carousel>
        </div>
    )
}