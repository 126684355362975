import './App.css';
import {ConfigProvider} from "antd";
import {UserProvider} from "./utils/UserProvider";
import AppRoutes from "./routes";

function App() {
  return (
      <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Poppins, sans-serif',
            },
          }}
      >
        <div className="App">
          <UserProvider>
            <AppRoutes/>
          </UserProvider>
        </div>
      </ConfigProvider>
  );
}

export default App;
