import axios from 'axios';
import { API_BASE_URL} from "../configs/app";


let AuthService = {
    login: async (body) => {
        return new Promise((resolve, reject) => {
            axios.post(`${API_BASE_URL}/v1/email/login?email=` + body.email + "&password=" + body.password, body)
            .then(response => {
                console.log('success', response.data)
                resolve(response.data);
            })
            .catch(err => {
                console.log('err', err);
                resolve(err.response.data);
            })
        });
    },

    register: async (body) => {
        return new Promise((resolve, reject) => {
            axios.post(`${API_BASE_URL}/v1/register`, body)
                .then(response => {
                    console.log('success', response.data)
                    resolve(response.data);
                })
                .catch(err => {
                    console.log('err', err);
                    resolve(err.response.data);
                })
        });
    },

    statesList: async () =>{
        return new Promise((resolve, reject) => {
            axios.get(`${API_BASE_URL}/v1/get/state/city`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.log('err', err);
                    resolve(err.response.data);
                })
        });
    },

    collegeList: async () =>{
        return new Promise((resolve, reject) => {
            axios.get(`${API_BASE_URL}/v1/get/colleges`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.log('err', err);
                    resolve(err.response.data);
                })
        });
    },

    sendEmailOtp: async (email) => {
        return new Promise((resolve, reject) => {
            axios.post(`${API_BASE_URL}/v1/email/otp`, {email: email})
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.log('err', err);
                    resolve(err.response.data);
                })
        });
    },

    sendMobileOtp: async (phone, resend = false) => {
        let url = `${API_BASE_URL}/v1/send/otp?phone=`+ phone;
        if(resend){
            url = `${API_BASE_URL}/v1/resend/otp`;
        }
        return new Promise((resolve, reject) => {
            axios.post(url, {phone: phone})
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.log('err', err);
                    resolve(err.response.data);
                })
        });
    },

    verifyEmail: async (body) => {
        return new Promise((resolve, reject) => {
            axios.post(`${API_BASE_URL}/v1/verify/email`, body)
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.log('err', err);
                    resolve(err.response.data);
                })
        });
    },

    verifyMobile: async (body) => {
        return new Promise((resolve, reject) => {
            axios.post(`${API_BASE_URL}/v1/verify/otp?phone=${body.phone}&otp=${body.otp}`, body)
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.log('err', err);
                    resolve(err.response.data);
                })
        });
    }
}

export default AuthService;