import React from "react";
import {AuthSlider} from "./AuthSlider/AuthSlider";

export function AuthLeftSection() {
    return (<div className="auth-left-section text-center">
        <div>
            <div>
                <img src="/logo.png" className="auth-logo" alt="logo"/>
            </div>
            <div>
                <img src="/logo-text.png" className="auth-logo-text" alt="logo text"/>
            </div>

            <AuthSlider/>
        </div>

    </div>)
}