import React from 'react';
import { Menu } from 'antd';
import {
    AppstoreOutlined,
    BarChartOutlined,
    DashboardOutlined, SettingOutlined,
    SmallDashOutlined,
    UserAddOutlined
} from "@ant-design/icons";
import {MenuProps} from "antd";

import './sider.css';
import {Link, Router, useNavigate} from "react-router-dom";

type MenuItem = Required<MenuProps>['items'][number];

const Sidebar = () => {
    const navigate = useNavigate();
    const items: MenuItem[] = [
        {
            key: 'sub1',
            icon: <AppstoreOutlined />,
            label: 'Dashboard',
            url: "/dashboard"
        },
        {
            key: 'sub2',
            icon: <BarChartOutlined />,
            label: "Study",
            url: "/cases/9"
        },
        {
            key: 'sub3',
            icon: <BarChartOutlined />,
            label: "Statistics"
        },{
            key: 'sub4',
            icon: <SettingOutlined />,
            label: "Settings"
        },

    ];

    const onClick: MenuProps['onClick'] = (e) => {
        let key = e.key;
        let item = items.find(i => i.key === key);
        navigate(item.url ?? '/dashboard');
    };

    return (
        <div className="sidebar">
            <div className={'sidebar-logo'}>
                <img src={'/sidebar-logo.png'} alt={'sidebar logo'}/>
            </div>
            <Menu defaultSelectedKeys={['sub1']} mode="inline" items={items} onClick={onClick}>
            </Menu>
        </div>
    );
}

export default Sidebar;