import './dashboard.css';
import UsersService from "../../services/users.service";
import {Col, Row} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import {PlayCircleFilled} from "@ant-design/icons";

export function Dashboard() {
    const user = UsersService.currentUser();

    return (
        <div>
            <Row>
                <Col xl={18} lg={24} md={24} className={"pr-3"}>
                    <div className={"welcome-container"}>
                        <Row align={'middle'} justify={'space-between'}>
                            <Col>
                                <h2>Welcome back, Dr. {user.first_name}
                                    <img src={"/images/waving-emoji.png"} width={32} className={'ml-2'} alt={'hello'}/></h2>
                                <p>You’ve learned <b>0%</b> of your goal this week!</p>
                                <p>Keep it up and improve your progress.</p>
                            </Col>
                            <Col>
                                <img src={"/images/human-space.png" } alt={"human-icon"} className={'md-none'}/>
                            </Col>
                        </Row>
                    </div>
                    <div className={'mt-3 px-4'}>
                        <h2 className={"app-section-title"}>Continue Learning
                            <Link to={'/cases/9'} className={'btn btn-primary ml-3 p-2'}><PlayCircleFilled /></Link>
                        </h2>
                    </div>
                </Col>
                <Col xl={6} lg={24} md={24} className={'px-2'}>
                    <h2 className={"app-section-title"}>Progress Report</h2>
                </Col>
            </Row>
        </div>
    );
}