import axios from 'axios';
import {API_BASE_URL} from "../configs/app";
import UsersService from "./users.service";


let CasesService = {

    caseById: async (id) => {
        return new Promise((resolve, reject) => {
            const token = UsersService.userToken();
            axios.get(`${API_BASE_URL}/v1/get/case/` + id, {
                headers: {
                    'Authorization' : 'Bearer '+ token,
                    "Accept": 'application/json'
                }
            }).then(response => {
                resolve(response.data);
            })
            .catch(err => {
                console.log('err', err);
                resolve(err.response.data);
            })
        });
    },

    caseQuestions: async (caseId) => {
        return new Promise((resolve, reject) => {
            const token = UsersService.userToken();
            axios.get(`${API_BASE_URL}/v1/get/quiz/case/` + caseId, {
                headers: {
                    'Authorization' : 'Bearer '+ token,
                    "Accept": 'application/json'
                }
            }).then(response => {
                resolve(response.data);
            })
                .catch(err => {
                    console.log('err', err);
                    resolve(err.response.data);
                })
        });
    }
}

export default CasesService;