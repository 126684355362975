import { useNavigate } from 'react-router-dom';
import {useContext, useEffect} from "react";
import {UserContext} from "../utils/UserContext";

export default function UserMiddleware({ component: ComposedComponent, isProtectedRoute = false }) {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user')); // Get user data from localStorage
        if (storedUser) {
            setUser(storedUser); // Set user data in context
        } else if (isProtectedRoute) {
            navigate('/login');
        }
    }, [navigate, setUser, isProtectedRoute]);

    return user ? <ComposedComponent /> : null;
}